import React, { useEffect, useRef, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import {
   Box,
   Button,
   ButtonGroup,
   Divider,
   FormControl,
   Grid,
   IconButton,
   MenuItem,
   Modal,
   Select,
   TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import toast from 'react-hot-toast'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { BeatLoader } from 'react-spinners'
import axios from '../api/axios'
import io from 'socket.io-client'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const columns = [
   { id: 'orderId', label: 'ID', minWidth: 100 },
   { id: 'client', label: 'Client', minWidth: 100 },
   { id: 'vehicleType', label: 'Vehicle Type', minWidth: 150 },
   { id: 'buildYear', label: 'Build Year', minWidth: 100 },
   { id: 'HP', label: 'Power (HP)', minWidth: 100 },
   { id: 'status', label: 'Status', minWidth: 50 },
   { id: 'tuningType', label: 'Tuning Type', minWidth: 100 },
   { id: 'createdAt', label: 'Upload Date', minWidth: 100 },
   { id: 'action', label: 'Action', align: 'center' },
]

const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: '90vw',
   bgcolor: 'background.paper',
   border: '0px',
   borderRadius: 1,
   boxShadow: 24,
   p: 0,
}

export default function AdminUpload() {
   const socket = io(process.env.REACT_APP_BASE_URL)
   const [creditsData, setCreditsData] = useState([])
   const [account,setAccount] = useState(null);
   const [page, setPage] = useState(0)
   const [myID, setMyID] = useState('')
   const [rowsPerPage, setRowsPerPage] = useState(10)
   const [uploadBtnFlag, setUploadBtnFlag] = useState(true)
   const [creditBtnFlag, setCreditBtnFlag] = useState(true)
   const [allData, setAllData] = useState([])
   const [oneData, setOneData] = useState({
      orderId: '',
      userId: '',
      client: '',
      fileSize: [],
      fileType: [],
      fileName: [],
      fileRename: [],
      vehicleType: '',
      vehicleBrand: '',
      vehicleEngine: '',
      vehicleSeries: '',
      buildYear: '',
      HP: '',
      KW: '',
      transmission: '',
      VINnumber: '',
      tuningType: '',
      extras: '',
      message: '',
      note: '',
      status: '',
      createdAt: '',
      availableCredit: 0,
      chargedCredit: 0,
      permission: '',
   })
   const [status, setStatus] = useState('')
   const [note, setNote] = useState('')
   const [credit, setCredit] = useState(0)
   const [serviceid, setServiceID] = useState()
   const [open, setOpen] = useState(false)
   const [fileData, setFileData] = useState({})
   const [isLoading, setIsLoading] = useState(false)
   const inputElement = useRef('fileInput')
   /* file modal */
   const [fileOpen, setFileOpen] = useState(false)
   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
   const [searchTerm, setSearchTerm] = useState('');

   const handleSort = (columnId) => {
      setSortConfig((prevConfig) => {
         if (prevConfig.key === columnId) {
            return {
               key: columnId,
               direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
            };
         }
         return { key: columnId, direction: 'asc' };
      });
   };

   const filteredData = React.useMemo(() => {
      if (!searchTerm) return allData;
      return allData.filter((row) =>
         columns.some((column) =>
            row[column.id]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
         )
      );
   }, [allData, searchTerm]);

   const sortedData = React.useMemo(() => {
      if (sortConfig.key) {
         return [...filteredData].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
               return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
               return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
         });
      }
      return filteredData;
   }, [filteredData, sortConfig]);

   useEffect(()=>{
      const user = localStorage.getItem('user');
      if(user){
         let parse = JSON.parse(user);
         setAccount(parse);
      }
   },[]);

   useEffect(() => {
      if(typeof serviceid === 'undefined') {
         // console.log("ServiceID is not defined")
         return
      }

      const obj = creditsData.find((item) => {
         return item.id === serviceid;
      })
      if(typeof obj !== 'undefined') {
         setCredit(obj.credit)
      }
   },[serviceid])
   
   const colorMap = {
      10: '#FFD700',
      25: '#FF4500',
      50: '#1E90FF',
   };

   const getRowColor = (permission) => {
      if (permission?.toLowerCase().startsWith('user') && permission.length > 4) {
         const number = parseInt(permission.slice(4), 10);
         return colorMap[number] || 'transparent';
      }
      return 'transparent';
   };

   const getDiscountPercent = (permission) => {
      if (typeof permission === 'string' && permission.toLowerCase().startsWith('user') && permission.length > 4) {
         const number = parseInt(permission.slice(4).trim(), 10);
         return isNaN(number) ? 0 : number;
      }
      return 0;
   };  

   const getDiscountedPrice = (number, permission) => {
      const permissionNumber = getDiscountPercent(permission);
      if (permissionNumber > 0) {
         return number - (number * (permissionNumber / 100));
      }
      return number;
   };   

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   const paginatedData = sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
   );

   const handleFileload = () => {
      inputElement.current.click()
   }

   const getFile = async (e) => {
      setFileData(e.target.files[0])
   }

   const upload = async () => {
      if (!uploadBtnFlag)
         if (!fileData.name) {
            toast.error('Select a file')
            return
         }
      if (!status) {
         toast.error('Select a status')
         return
      }
      if (!creditBtnFlag) {
         if (serviceid < 0) {
            toast.error('Select a credit charge')
            return
         }
      }
      if (!isLoading) {
         setIsLoading(true)
         try {
            let cost = getDiscountedPrice(credit, oneData.permission);
            if (status === 'completed') {
               let params = new FormData()
               const data = {
                  id: oneData._id,
                  userId: oneData.userId,
                  orderId: oneData.orderId,
                  status,
                  note,
                  credit: cost,
                  date: getCustomDate(),
               }
               params.append('file', fileData)
               params.append('data', JSON.stringify(data))
               await axios
                  .post(`updateUpload`, params)
                  .then((result) => {
                     if (result.data.status) {
                        toast.success(result.data.data)
                        getRequests()
                        setOpen(false)
                        setFileData('')
                        setStatus('')
                        setNote('')
                        setServiceID()
                        setCredit(0)
                        socket.emit('reply', {
                           from: myID,
                           to: oneData.userId,
                           orderId: oneData.orderId,
                        })
                        setIsLoading(false)
                     } else {
                        toast.error(result.data.data)
                     }
                  })
            } else {
               const data = {
                  id: oneData._id,
                  userId: oneData.userId,
                  status,
                  note,
                  credit: cost,
                  orderId: oneData.orderId,
               }
               await axios
                  .post(`uploadStatusSave`, {
                     data,
                  })
                  .then((result) => {
                     if (result.data.status) {
                        toast.success(result.data.data)
                        getRequests()
                        setOpen(false)
                        setStatus('')
                        setNote('')
                        setServiceID()
                        setCredit(0)
                        socket.emit('reply', {
                           from: myID,
                           to: oneData.userId,
                           orderId: oneData.orderId,
                        })
                        setIsLoading(false)
                     } else {
                        toast.error(result.data.data)
                     }
                  })
            }
         } catch (error) {
            if (process.env.REACT_APP_MODE) console.log(error)
         }
      } else {
         toast.error('Loading...')
      }
   }

   const getOneRequest = async (id) => {
      try {
         await axios
            .post(`getOneRequest`, { id })
            .then((result) => {
               if (result.data.status) {
                  let data = result.data.data
                  data.availableCredit = result.data.available
                  data.chargedCredit = result.data.charged
                  setOneData(data)
                  setStatus(data.status)
                  setNote(data.note)
                  setServiceID()
                  setCredit(0)
                  setOpen(true)
               } else {
                  toast.error(result.data.data)
               }
            })
      } catch (error) {
         if (process.env.REACT_APP_MODE) console.log(error)
      }
   }

   const handleClose = () => setOpen(false)

   const getRequests = async () => {
      try {
         await axios
            .post(`getRequests`)
            .then((result) => {
               if (result.data.status) {
                  setAllData(result.data.data)
               } else {
                  toast.error(result.data.data)
               }
            })
      } catch (error) {
         if (process.env.REACT_APP_MODE) console.log(error)
      }
   }

   const changeStatus = async (id, userId, orderId) => {
      try {
         await axios
            .post(`changeStatus`, { id })
            .then((result) => {
               if (result.data.status) {
                  toast.success(result.data.data)
                  socket.emit('reply', {
                     from: myID,
                     to: userId,
                     orderId,
                  })
                  getRequests()
               } else {
                  toast.error(result.data.data)
               }
            })
      } catch (error) {
         if (process.env.REACT_APP_MODE) console.log(error)
      }
   }

   const getCustomDate = () => {
      const d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      let hour = d.getHours()
      let minute = d.getMinutes()

      if (month < 10) month = '0' + month
      if (day < 10) day = '0' + day
      if (hour < 10) hour = '0' + hour
      if (minute < 10) minute = '0' + minute
      const ampm = hour >= 12 ? 'pm' : 'am'

      return `${day}-${month}-${year} ${hour}:${minute} ${ampm}`
   }

   const deleteFile = () => {
      setFileData({})
      setFileOpen(false)
   }

   const getAllPrice = async () => {
      try {
         await axios
            .post(`getAllPrice`)
            .then((result) => {
               if (result) {
                  let data = result.data.map((item)=>{
                     return {credit: item.credit, service: item.service, id: item._id}
                  })
                  data.unshift({credit: 0, service: "No charge", id: 0})
                  // console.log(data)
                  setCreditsData(data)
               } else {
                  toast.error('Internal server error')
               }
            })
      } catch (error) {
         if (process.env.REACT_APP_MODE) console.log(error)
      }
   }

   useEffect(() => {
      getRequests()
      getAllPrice()
   }, [])

   useEffect(() => {
      if (status === 'completed') {
         setUploadBtnFlag(false)
         setCreditBtnFlag(false)
      } else if (status === 'in-progress') {
         setUploadBtnFlag(true)
         setCreditBtnFlag(true)
      } else {
         setUploadBtnFlag(true)
         setCreditBtnFlag(true)
      }
   }, [status])

   useEffect(() => {
      if (account) {
         setMyID(account?._id)
      }
   }, [account])

   useEffect(() => {
      if (fileData?.name) setFileOpen(true)
      else setFileOpen(false)
   }, [fileData])

   return (
      <Paper
         sx={{
            width: '100%',
            overflow: 'hidden',
            p: 3,
            overflowY: 'overlay',
         }}
      >
         <div style={{marginBottom: '10px'}}>Requests</div>
         <TextField
            label="Search and filter"
            variant="outlined"
            fullWidth
            sx={{ mb: 2, width: '300px' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
         />
         <TableContainer
            sx={{
               mt: 2,
            }}
         >
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={column.id}
                           align={column.align}
                           style={{
                              minWidth: column.minWidth,
                              backgroundColor: '#3791e9',
                              color: 'white',
                              cursor: 'pointer',
                           }}
                           onClick={() => handleSort(column.id)}
                        >
                           {column.label}{' '}
                           {sortConfig.key === column.id && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {paginatedData.map((row) => (
                     <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                        style={{ backgroundColor: getRowColor(row.permission) }}
                     >
                        {columns.map((column) => {
                           let value = ''
                           if (column.id === 'buildYear') {
                              const d = new Date(row.buildYear)
                              value = d.getFullYear()
                           } else {
                              value = row[column.id]
                           }
                           return (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                              >
                                 {column.id === 'action' ? (
                                    <ButtonGroup
                                       variant="outlined"
                                       aria-label="outlined button group"
                                    >
                                       {row.status.toLowerCase() === 'requested' ? (
                                          <IconButton
                                             onClick={() =>
                                                changeStatus(
                                                   row._id,
                                                   row.userId,
                                                   row.orderId
                                                )
                                             }
                                             color="primary"
                                             aria-label="add to shopping cart"
                                          >
                                             <AutoModeIcon />
                                          </IconButton>
                                       ) : (
                                          <></>
                                       )}
                                       <IconButton
                                          onClick={() =>
                                             getOneRequest(row._id)
                                          }
                                          color="primary"
                                          aria-label="add to shopping cart"
                                       >
                                          <VisibilityIcon />
                                       </IconButton>
                                    </ButtonGroup>
                                 ) : column.id === 'status' ? (
                                     value.toLowerCase() === 'in-progress' ? "In progress" : value
                                 ) : (
                                    value
                                 )}
                              </TableCell>
                           );
                        })}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
         <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <Box
                  sx={{
                     px: 3,
                     py: 1,
                     bgcolor: '#1976d2',
                     borderRadius: 1,
                     color: 'white',
                     display: 'flex',
                     alignItems: 'center',
                     position: 'fixed',
                     width: '100%',
                  }}
               >
                  <Box>View Complete Service Request</Box>
                  <Box sx={{ flex: '1' }}></Box>
                  <Box>
                     <IconButton
                        onClick={() => {
                           handleClose()
                        }}
                     >
                        <CloseIcon sx={{ color: 'white' }} />
                     </IconButton>
                  </Box>
               </Box>
               <Box
                  sx={{
                     p: 3,
                     mt: 9,
                     height: '70vh',
                     overflowY: 'overlay',
                  }}
               >
                  <Box>
                     <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                     >
                        <Grid
                           item
                           xs={12}
                           sm={6}
                           md={6}
                           sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '20px',
                           }}
                        >
                           <Box
                              sx={{
                                 display: 'flex',
                                 justifyContent: 'flex-start',
                                 border: '1px dotted',
                                 borderRadius: '20px',
                                 flexDirection: 'column',
                                 p: '30px',
                                 gap: '10px',
                                 alignItems: 'center',
                              }}
                           >
                              {fileOpen ? (
                                 <Box className="select-file" mt={4}>
                                    <Box className="file-group-box">
                                       <Box className="upload-img-box">
                                          <UploadFileIcon className="upload-img-icon" />
                                       </Box>
                                       <Box className="file-info">
                                          <Box>
                                             <Box className="filename-string">
                                                {fileData?.name}
                                             </Box>
                                             <Box>
                                                {(
                                                   fileData?.size / 1000000
                                                ).toFixed(4)}{' '}
                                                MB
                                             </Box>
                                          </Box>
                                       </Box>
                                    </Box>
                                    <Box className="close-img-box">
                                       <HighlightOffIcon
                                          className="close-img-icon"
                                          onClick={() => deleteFile()}
                                       />
                                    </Box>
                                 </Box>
                              ) : (
                                 <>
                                    <Box>
                                       <CloudUploadIcon
                                          sx={{
                                             color: '#1976d2',
                                             fontSize: '70px',
                                          }}
                                       />
                                    </Box>
                                    <Box>Drag and drop your file here</Box>
                                    <Box>or</Box>
                                 </>
                              )}

                              <Box>
                                 <input
                                    ref={inputElement}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => getFile(e)}
                                 />
                                 <Button
                                    variant="outlined"
                                    sx={{
                                       border: '1px solid #1976d2',
                                       borderRadius: '12px',
                                       color: '#1976d2',
                                    }}
                                    disabled={uploadBtnFlag}
                                    onClick={handleFileload}
                                 >
                                    Browse File
                                 </Button>
                              </Box>
                           </Box>
                           <Box>
                              Available Credits: {oneData.availableCredit}
                           </Box>
                           <Box>Charged Credits: {oneData.chargedCredit}</Box>
                           <Box>
                              Update Status
                              <FormControl fullWidth size="small">
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                 >
                                    <MenuItem value="completed">
                                       Completed
                                    </MenuItem>
                                    <MenuItem value="in-progress">
                                       In progress
                                    </MenuItem>
                                    <MenuItem value="cancelled">
                                       Cancelled
                                    </MenuItem>
                                 </Select>
                              </FormControl>
                           </Box>
                           <Box>
                              Note
                              <TextField
                                 placeholder={
                                    oneData.chargedCredit > 0
                                       ? oneData.note === '' ? '' :
                                       `X-note : ${oneData.note}` :
                                       'Add a short note'
                                 }
                                 variant="outlined"
                                 size="small"
                                 fullWidth
                                 value={note}
                                 onChange={(e) => setNote(e.target.value)}
                              />
                           </Box>
                           <Box>
                              Charge Credits
                              <Select
                                 labelId="demo-simple-select-filled-label"
                                 id="demo-simple-select-filled"
                                 size="small"
                                 fullWidth
                                 disabled={creditBtnFlag}
                                 value={serviceid}
                                 onChange={(e) => setServiceID(e.target.value)}
                              >
                                 {creditsData.map((item, key) => {
                                    return (
                                       <MenuItem key={key} value={item.id}>
                                       {item.service + ' (' + getDiscountedPrice(item.credit, oneData?.permission) + ' credits)'}
                                       </MenuItem>
                                    )
                                 })}
                              </Select>
                           </Box>
                           <Box>
                              <Button
                                 variant="contained"
                                 size="small"
                                 fullWidth
                                 endIcon={<ArrowForwardIcon />}
                                 onClick={upload}
                              >
                                 {isLoading ? (
                                    <BeatLoader color="#fff" size={10} />
                                 ) : (
                                    'Upload'
                                 )}
                              </Button>
                           </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Box>
                              <Box
                                 sx={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 Request From ({oneData?.client})
                              </Box>
                              <Divider />
                              <Grid lineHeight={'4px'}>
                                 <Grid
                                   container
                                   spacing={{ xs: 2, md: 3 }}
                                   columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                   <Grid item xs={12} sm={6} md={6}>
                                     <Box sx={{ my: '20px' }}>Name:</Box>
                                   </Grid>
                                   <Grid item xs={12} sm={6} md={6}>
                                     <Box sx={{
                                          backgroundColor: getRowColor(oneData?.permission),
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          position: "relative",
                                          "::before": {
                                            content: '""',
                                            position: "absolute",
                                            left: "-7px",
                                            top: 0,
                                            bottom: 0,
                                            width: "7px",
                                            backgroundColor: getRowColor(oneData?.permission),
                                          },
                                       }}>
                                       {oneData?.client}
                                       {oneData?.permission && oneData.permission.includes('user') ?
                                        getDiscountPercent(oneData.permission) > 0 ?
                                        ` (${getDiscountPercent(oneData.permission)} %)`: '' : ''}
                                     </Box>
                                   </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          Vehicle Type:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.vehicleType}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          Vehicle Series:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.vehicleSeries}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          Build Year:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {new Date(
                                             oneData?.buildYear
                                          ).getFullYear()}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>HP:</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.HP}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>KW:</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.KW}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          Transmission:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.transmission}
                                       </Box>
                                    </Grid>
                                 </Grid>

                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          VINnumber#:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.VINnumber}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          Tuning Type:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.tuningType}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>Extras:</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.extras}
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          Work Status:
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>
                                          {oneData?.status.toLowerCase() === 'in-progress' ? "In progress" :
                                           oneData?.status.charAt(0).toUpperCase() + oneData?.status.slice(1)}
                                       </Box>
                                    </Grid>
                                 </Grid>

                                 <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                 >
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box sx={{ my: '20px' }}>Message:</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                       <Box
                                          sx={{
                                             my: '20px',
                                             overflowWrap: 'break-word',
                                             lineHeight: '20px',
                                          }}
                                       >
                                          {oneData?.message}
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Grid>

                              <Grid
                                 container
                                 spacing={{ xs: 2, md: 3 }}
                                 columns={{ xs: 4, sm: 8, md: 12 }}
                              >
                                 <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                       sx={{
                                          my: '20px',
                                          fontSize: '20px',
                                          fontWeight: '600',
                                       }}
                                    >
                                       Upload
                                    </Box>
                                    <ul>
                                       <li className="file-admin-download-name">
                                          <a
                                             href={`${process.env.REACT_APP_BASE_URL}/fileService/${oneData?.fileRename[0]}`}
                                             download={oneData?.fileName[0]}
                                          >
                                             {oneData?.fileName[0]}
                                          </a>
                                       </li>
                                    </ul>
                                 </Grid>
                              </Grid>
                              <Grid
                                 container
                                 spacing={{ xs: 2, md: 3 }}
                                 columns={{ xs: 4, sm: 8, md: 12 }}
                              >
                                 <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                       sx={{
                                          my: '20px',
                                          fontSize: '20px',
                                          fontWeight: '600',
                                       }}
                                    >
                                       Uploaded by Support team
                                    </Box>
                                    <ul>
                                       {oneData?.fileName.map((item, ind) =>
                                          ind === 0 ? (
                                             <p key={ind}></p>
                                          ) : (
                                             <li
                                                key={ind}
                                                className="file-admin-download-name"
                                             >
                                                <a
                                                   href={`${process.env.REACT_APP_BASE_URL}/fileService/${oneData?.fileRename[ind]}`}
                                                   download={item}
                                                >
                                                   {item}
                                                </a>
                                             </li>
                                          )
                                       )}
                                    </ul>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Box>
            </Box>
         </Modal>
      </Paper>
   )
}
